import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

export default function Form() {
  const form = useRef(null);
  const [formStatus, setFormStatus] = useState("idle"); // 'idle', 'success', 'error'
  const [department, setDepartment] = useState("");
  const [doctor, setDoctor] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (!form.current) return; // Ensure form exists

    emailjs
      .sendForm("service_t2a99e8", "template_rxz1tt6", form.current, {
        publicKey: "XmKF93KKRhNUdiAh2",
      })
      .then(
        () => {
          setFormStatus("success");
          if (form.current) {
            form.current.reset(); // Reset the form fields
          }
          setDepartment(""); // Reset department state
          setDoctor(""); // Reset doctor state
          setTimeout(() => {
            setFormStatus("idle");
          }, 3000); // Show thank you message for 3 seconds
        },
        (error) => {
          console.log("FAILED...", error.text);
          setFormStatus("error");
          setTimeout(() => {
            setFormStatus("idle");
          }, 3000); // Show error message for 3 seconds
        }
      );
  };

  return (
    <>
      {formStatus === "success" && (
        <div className="text-center text-green-500 sofia-pro">
          Thank you for submitting the form!
        </div>
      )}
      {formStatus === "error" && (
        <div className="text-center text-red-500 sofia-pro">
          There was an error submitting the form. Please try again.
        </div>
      )}
      {formStatus === "idle" && (
        <form ref={form} onSubmit={sendEmail}>
          <input
            className="rounded-lg bg-newshade12 xl:w-12/12 xl:py-2 lg:w-12/12 md:w-12/12 w-12/12 lg:py-2 border-11 py-2 sofia-pro"
            type="text"
            id="text"
            name="name"
            placeholder="Your Name"
            required
          />
          <input
            className="sofia-pro rounded-lg  bg-newshade12 xl:w-12/12 xl:py-2 lg:w-12/12 md:w-12/12 w-12/12 lg:py-2 lg:mt-4 xl:mt-4 pl-2 py-2 mt-2"
            type="tel"
            id="phone"
            name="tel"
            placeholder="Phone Number"
            required
          /> 
          <input
            className="sofia-pro rounded-lg bg-newshade12 xl:w-12/12 xl:py-2 lg:w-12/12 md:w-12/12 w-12/12 mt-2 lg:py-2 xl:mt-4 lg:mt-4 border-11 py-2"
            type="text"
            id="text"
            name="email"
            placeholder="Your Email"
            required
          />
          <select
            className="sofia-pro text-grey-600 rounded-lg bg-newshade12 xl:w-12/12 xl:py-2 lg:w-12/12 md:w-12/12 w-12/12 lg:py-2 py-2 lg:mt-4 xl:mt-4 border-11 mt-2"
            id="department"
            name="department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            required
          >
            <option value="" disabled>
              Choose Department
            </option>
            <option value="gynecology">Fertility</option>
            <option value="pediatrics">Pediatrics</option>
            <option value="cardiology">Dermatology</option>
            <option value="orthopedics">Postnatal Care</option>
            <option value="neurology">Laparoscopic Surgery</option>
            <option value="dermatology">Pysiotherapy</option>
            <option value="radiology">Neonatology</option>
            <option value="surgery">Gynecological Oncology</option>
            <option value="emergency">ANC Care</option>
            <option value="emergency">High Risk Pregnancy</option>
            <option value="emergency">Obstetrics</option>
            <option value="emergency">Fetal Medicine</option>
            <option value="emergency">Family planning</option>
            <option value="emergency">Well woman clinic</option>
            <option value="emergency">Vaccination</option>
            <option value="emergency">Adolescent Health</option>
            <option value="emergency">NICU</option>
            <option value="emergency">Cosmetology</option>
            <option value="emergency">General Surgery</option>
            <option value="emergency">Urology and Urogynaecology</option>
          </select>
          <select
            className="sofia-pro text-grey-600 rounded-lg xl:w-12/12 xl:py-2 lg:w-12/12 md:w-12/12 w-12/12 lg:py-2 py-2 lg:mt-4 xl:mt-6 bg-newshade12 mt-4 border-11"
            id="doctor"
            name="doctor"
            value={doctor}
            onChange={(e) => setDoctor(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Doctor
            </option>
            <option value="Dr.Ambuja Govindaraj">Dr.Ambuja Govindaraj</option>
            <option value="Dr Bhagyashri C Andani">Dr Bhagyashri C Andani</option>
            <option value="Dr Pushpa A">Dr Pushpa A</option>
            <option value="Dr Meghana Murthy">Dr Meghana Murthy</option>
            <option value="Dr Shruthi Madhavi G">Dr Shruthi Madhavi G</option>
            <option value="Dr.vijetha">Dr.Vijetha</option>
            <option value="Dr.Sreenivas">Dr.Sreenivas</option>
          </select>
          <input
            className="sofia-pro text-grey-600 uppercase rounded-lg bg-newshade12 xl:w-12/12 xl:py-2 lg:w-12/12 md:w-12/12 w-12/12 lg:py-2 py-2 lg:mt-4 xl:mt-4 border-11 mt-2 px-3"
            type="Date"
            id="date"
            name="date"
            placeholder="Date"
            required
            style={{ outline: 'none', border: 'none' }}
          />
          <div className="center-button">
            <button
              className="sofia-pro bg-gradient rounded-lg xl:px-10 xl:py-2 text-white xl:mt-6 lg:mt-4 px-6 py-2 mt-4"
              style={{ outline: 'none' }}
            >
              Book an Appointment
            </button>
          </div>
        </form>
      )}
    </>
  );
}
