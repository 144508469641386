import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import meetdoctor from "assets/img/meet-doctor.png";
import nurturebannerr from "assets/img/nurturebannerr.png";
import servicesbanner from "assets/img/servicesbanner.png";
import banner1 from "assets/img/Banner-01.png";
import banner2 from "assets/img/Banner-02.png";
import nurturedoc1 from "assets/img/nurturedoc1.png";
import nurturedoc2 from "assets/img/nurturedoc2.png";
import nurturedoc3 from "assets/img/nurturedoc3.png";
import nurturedoc4 from "assets/img/nurturedoc4.png";
import rekha from "assets/img/Rekha-Gogi.png";
import Login from "views/auth/Login"; // Ensure this import is correct
import doctorvijetha from "assets/img/doctorvijetha.png";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-red-500 cursor-pointer"
      style={{ display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* Custom Previous Arrow Content */}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-blue-500 cursor-pointer"
      style={{ display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* Custom Next Arrow Content */}
    </div>
  );
};

const Cloudslider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (current) => setCurrentSlide(current),
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slides = [
    { backgroundImage: `url(${banner1})`, text: "Oil" },
    { backgroundImage: `url(${banner2})`, text: "Gas" },
  ];

  return (
    <section className="grotesk">
      <Slider {...settings} prevArrow={<CustomPrevArrow />} nextArrow={<CustomNextArrow />}>
        {slides.map((slide, index) => (
          <div key={index}>
            <div
              className="xl:h-screen lg:h-600 md:h-540 h-500-px"
              style={{
                backgroundImage: slide.backgroundImage,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="class">
                <div className="container mx-auto xl:px-4 lg:px-4 md:px-12 px-4 ">
                  <div className="flex xl:pt-72 lg:pt-56 md:pt-52 pt-44"></div>

                  <div className={`${currentSlide === index ? "fade-in-up" : ""}`}>
                    <h1 className="xl:text-6xl text-iceblue1 lg:text-4xlll md:text-4xl text-3xl figtree">
                      {currentSlide === 0 ? (
                        <>
                           Admire the baby <br />
                          <span className="xl:text-6xl font-bold text-sandybrown1 lg:text-4xlll md:text-4xl text-3xl">
                            and nurture the mother
                          </span>
                        </>
                      ) : (
                        <>
                          Your trusted healthcare <br />
                          <span className="xl:text-6xl font-bold text-sandybrown1 lg:text-4xlll md:text-4xl text-3xl">
                          partner for women & children 
                          </span>
                        </>
                      )}
                    </h1>

                    <div>
                      <h1 className="xl:text-base1 text-nightblack xl:mt-5 lg:text-xl lg:mt-4 md:text-xl text-xl md:mt-4 mt-4 sofia-pro">
                        Meet <span className="font-bold">our doctors</span>
                      </h1>

                      <div className="flex">
                        <div>
                          <div className="flex xl:mt-2 lg:mt-2 mt-2">
                            <div className="border-5 rounded-full">
                              <img className="w-12 rounded-full" src={nurturedoc2} alt="" />
                            </div>
                            <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                              <div className="border-5 rounded-full">
                                <img className="w-12 rounded-full" src={rekha} alt="" />
                              </div>
                            </div>

                            <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                              <div className="border-5 rounded-full">
                                <img className="w-12 rounded-full" src={nurturedoc4} alt="" />
                              </div>
                            </div>
                            <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                              <div className="border-5 rounded-full">
                                <img className="w-12 rounded-full" src={nurturedoc3} alt="" />
                              </div>
                            </div>
                            <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                              <div className="border-5 rounded-full">
                                <img className="w-12 rounded-full" src={doctorvijetha} alt="" />
                              </div>
                            </div>
                            <div className="xl:-ml-4 z-10 mt-1 lg:-ml-4 -ml-4">
                              <div className="bg-gradient h-12 w-12 rounded-full border-5">
                                <a href="/ourteam">
                                  <span className="text-snowhite xl:text-2xl lg:text-2xl text-2xl pl-3 pt-2 text-center">
                                    +
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="xl:ml-4 lg:ml-4 md:ml-4 ml-1 xl:mt-3 lg:mt-4 md:mt-4 sofia-pro xl:block lg:block md:block sm:hidden hidden">
                          <button
                            className="text-snowhite xl:text-base xl:px-4 xl:py-3 rounded-md bg-gradient xl:px-4 md:px-4 px-2 py-2"
                            style={{ outline: "none" }}
                            onClick={openPopup} // Open the popup on button click
                          >
                            Book an Appointment
                          </button>
                        </div>
                      </div>
                      <div className="mt-4 sofia-pro xl:hidden lg:hidden md:hidden sm:block block">
                        <button
                          className="text-snowhite rounded-md bg-gradient px-2 py-2"
                          style={{ outline: "none" }}
                          onClick={openPopup} // Open the popup on button click
                        >
                          Book an Appointment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Render the popup conditionally */}
      {showPopup && (
        <div className="inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Login closePopup={closePopup} />
        </div>
      )}
    </section>
  );
};

export default Cloudslider;
